/* Colors Neuhaus */
/* Fonts Neuhaus */
/* Colors Neuhaus */
/* Fonts Neuhaus */
html,
body,
.page {
  color: #503223;
  background-color: #fff;
  line-height: 1.3em;
  min-height: 100vh;
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

body {
  font-size: 1rem; }

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

div[role="main"] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

/* stylelint-disable-next-line */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .browserModal {
    display: block !important;
    top: inherit; }
    .browserModal .modal-text {
      max-width: 450px; }
  .modal-background {
    display: block;
    z-index: 500;
    top: 0; }
  body {
    overflow: hidden; } }

a.link {
  color: #503223;
  text-decoration: underline;
  font-weight: 700; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.h1,
h1 {
  font-size: calc(1.425rem + 2.1vw); }
  @media (min-width: 1200px) {
    .h1,
    h1 {
      font-size: 3rem; } }

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    .h2,
    h2 {
      font-size: 2rem; } }

h3,
.h3 {
  font-size: 1.25rem; }

h4,
.h4 {
  font-size: 1.125rem; }

h5,
.h5 {
  font-size: 1rem; }

h6,
.h6 {
  font-size: 0.875rem; }

.font-link, .font-caption, .font-title, .font-heading {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.font-copy {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

a,
a:hover,
a:active,
a:focus {
  color: #503223; }

.checkout-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .checkout-header .page-title {
    width: auto;
    white-space: nowrap; }
    @media (min-width: 992px) {
      .checkout-header .page-title {
        width: 100%; } }
  .checkout-header .checkout-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .checkout-header .checkout-steps .step {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      max-width: 160px; }
      .checkout-header .checkout-steps .step .step-number {
        background: #503223;
        color: white;
        font-size: 1.25rem;
        text-align: center;
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 20px; }
      .checkout-header .checkout-steps .step .step-name {
        font-weight: 700;
        font-size: 0.875rem;
        margin-top: 8px;
        text-align: center; }
        @media (min-width: 769px) {
          .checkout-header .checkout-steps .step .step-name {
            font-size: 1rem; } }
      .checkout-header .checkout-steps .step.active ~ .step .step-number {
        background: #a5a5a5; }
      .checkout-header .checkout-steps .step.active ~ .step .step-name {
        font-weight: 400;
        color: #a5a5a5; }
    @media (min-width: 992px) {
      .checkout-header .checkout-steps {
        position: absolute;
        top: 0;
        height: 100%;
        margin-top: 1rem; } }

.checkout-header .active.step,
.checkout-header .active.step ~ .step,
.checkout-confirmation-header .step {
  pointer-events: none; }

/* CUSTOM */
.product-line-item {
  margin: 0;
  margin-bottom: 1rem; }
  .product-line-item:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem; }
  .product-line-item .product-line-item-details {
    overflow-y: visible;
    padding: 0; }
  .product-line-item .product-line-item-content {
    padding-right: 0; }
  .product-line-item .line-item-total-price .price {
    margin-top: 0; }
  .product-line-item .custom-product-properties {
    margin-bottom: 0;
    margin-top: 0.5rem; }

.order-total-summary {
  padding: 1rem 20px 0;
  border-bottom: 1px solid #ddd; }
  .order-total-summary .order-receipt-label,
  .order-total-summary .order-receipt-price {
    font-family: "Flama", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 1.25rem;
    color: #7c655a; }

.order-product-summary {
  padding: 1rem 20px 0; }
  .order-product-summary .item-image {
    height: auto; }

.view-address-block .price,
.shipment-block .price {
  color: #7c655a; }

.shipment-block-heading {
  background-color: #a5a5a5;
  opacity: 0.75; }

.multi-ship .billing-address-block .checkbox-wrapper {
  display: none; }

/* SFRA */
.order-receipt-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.shipping-method {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.summary-details .address-summary {
  margin-bottom: 0.5em; }

.summary-section-label {
  font-size: 1em;
  font-weight: 600; }
  @media (min-width: 992px) {
    .summary-section-label {
      font-weight: 500; } }

.add-payment {
  margin-top: 1rem; }

.saved-security-code {
  margin-top: 1rem; }

.saved-credit-card-type {
  font-weight: 600; }

.saved-payment-information {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.payment-information {
  margin-bottom: 1rem; }

.checkout-hidden {
  display: none; }

.saved-payment-instrument:not(.selected-payment) {
  cursor: pointer; }

.card-image {
  margin-top: 0.5rem;
  width: 100%;
  max-height: 50px; }

.cancel-new-payment {
  margin-top: 1rem; }

.form-check.start-lines {
  padding-left: 1.5rem; }

.multi-ship .single-shipping .shipping-content {
  display: none; }

.multi-ship .shipping-summary .single-shipping {
  display: none; }

.gift-message-block {
  padding-bottom: 1em; }

.single-shipping .summary-section-label {
  margin-bottom: 0; }

.confirm-details .shipping-method,
.confirm-details .shipping-method-price {
  margin-bottom: 0; }

.multi-ship .confirm-details .single-shipping {
  display: none; }

.multi-shipping {
  display: none; }
  @media (max-width: 991.98px) {
    .multi-shipping-summary {
      border-top: 1px solid #ddd;
      padding-top: 1.5rem; } }
  @media (min-width: 992px) {
    .multi-shipping-summary {
      border-left: 1px solid #ddd;
      padding-left: 1.5rem; } }

.form-group.required .form-control-label::before {
  content: "*";
  color: #b14350; }

.card-number-wrapper {
  position: relative; }
  .card-number-wrapper::after {
    content: '';
    position: absolute;
    right: 3px;
    background-repeat: no-repeat;
    background-image: url("../../images/credit.png");
    background-size: contain;
    width: 48px;
    height: 30px;
    top: 5px; }
  .card-number-wrapper[data-type="visa"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -162px -110px; }
  .card-number-wrapper[data-type="mastercard"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -295px -110px; }
  .card-number-wrapper[data-type="amex"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -230px -15px; }
  .card-number-wrapper[data-type="discover"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -95px -110px; }

.cancel-giftcards-link {
  cursor: pointer; }

.gift-card-logo {
  max-width: 40px; }

/* CUSTOM */
textarea {
  resize: none; }

.collapse-giftcard-info button .icon {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.collapse-giftcard-info button.collapsed .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.collapse-giftcard-info button:focus {
  -webkit-box-shadow: none;
          box-shadow: none; }

.minicart-pname,
.minicart-pimg {
  display: none; }

.checkout-summary-card .card-header {
  margin: 0 20px; }

.card:not(.checkout-summary-card) {
  border: none;
  border-radius: 0; }
  .card:not(.checkout-summary-card) .card-header {
    border-bottom: none;
    padding: 0 0 25px; }
    .card:not(.checkout-summary-card) .card-header .card-header-custom {
      font-size: 2rem; }
  .card:not(.checkout-summary-card) .card-body {
    padding: 0; }

.shipping-summary .edit-btn,
.payment-summary .edit-btn,
.confirm-details .edit-btn {
  position: absolute;
  right: 0;
  top: 100%;
  cursor: pointer;
  z-index: 1; }

.shipping-summary .card-body .row,
.payment-summary .card-body .row,
.confirm-details .card-body .row {
  padding-bottom: 1rem; }
  .shipping-summary .card-body .row h3,
  .shipping-summary .card-body .row p,
  .payment-summary .card-body .row h3,
  .payment-summary .card-body .row p,
  .confirm-details .card-body .row h3,
  .confirm-details .card-body .row p {
    margin-bottom: 0; }
  @media (min-width: 769px) {
    .shipping-summary .card-body .row::after,
    .payment-summary .card-body .row::after,
    .confirm-details .card-body .row::after {
      content: "";
      margin-left: 15px;
      width: calc(100% - 30px);
      border-bottom: 1px solid #ddd;
      margin-top: 1rem; } }

.form-group.required .form-control-label::before {
  content: unset; }

.shipping-cost {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1.25rem;
  color: #7c655a;
  font-weight: 400; }

.custom-multishipping-checkbox {
  padding-left: 0; }
  .custom-multishipping-checkbox label {
    font-size: 1rem;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #fff;
    font-weight: 400;
    min-height: 50px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%; }
    .custom-multishipping-checkbox label::before {
      content: ""; }

.info-icon {
  display: none; }

.terms-and-conditions,
.terms-and-conditions a {
  color: #7c655a; }

.shipping-method-list > div {
  margin-top: 0.5rem; }

.shipping-addr-warning {
  background-color: rgba(255, 193, 7, 0.3); }

/* SFRA */
.billing-address {
  display: block; }

.edit-button,
.btn-show-details,
.btn-add-new {
  cursor: pointer; }
  .edit-button:hover,
  .btn-show-details:hover,
  .btn-add-new:hover {
    text-decoration: underline; }

.error-message {
  display: none; }

.next-step-button {
  padding-right: 0;
  padding-left: 0; }
  @media (max-width: 543.98px) {
    .next-step-button > div {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 544px) {
    .next-step-button {
      position: static;
      padding-right: 0.938em;
      padding-left: 0.938em; } }

.shipping-methods {
  font-size: 1.125em;
  font-weight: 600; }

.shipping-method-pricing {
  font-weight: bolder; }

.multi-ship .multi-shipping {
  display: block; }

span.ship-to-name,
span.ship-to-address1,
span.ship-to-address2,
span.ship-to-phone,
span.ship-to-city-st-zip {
  display: block; }

.data-checkout-stage[data-checkout-stage] .card.payment-summary,
.data-checkout-stage[data-checkout-stage] .shipping-summary {
  display: none; }

.data-checkout-stage[data-checkout-stage] button.place-order,
.data-checkout-stage[data-checkout-stage] button.submit-payment,
.data-checkout-stage[data-checkout-stage] button.submit-shipping,
.data-checkout-stage[data-checkout-stage] .previous-step-button {
  display: none; }

.data-checkout-stage[data-checkout-stage] .terms-and-conditions {
  display: none; }

.data-checkout-stage[data-checkout-stage="shipping"] .evoucher-shipping-card,
.data-checkout-stage[data-checkout-stage="shipping"] .evouchers-shipment-block-heading {
  display: none; }

.data-checkout-stage[data-checkout-stage="shipping"].multi-ship .order-product-summary {
  display: none; }

.data-checkout-stage[data-checkout-stage="shipping"] .card.payment-form {
  display: none; }

.data-checkout-stage[data-checkout-stage="shipping"] button.submit-shipping,
.data-checkout-stage[data-checkout-stage="shipping"] .back-from-shipping {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.data-checkout-stage[data-checkout-stage="shipping"] .shipment-selector-block .btn-show-details,
.data-checkout-stage[data-checkout-stage="shipping"] .shipment-selector-block .btn-add-new {
  border: none; }

.data-checkout-stage[data-checkout-stage="shipping"] .terms-and-conditions-link {
  display: none; }

.data-checkout-stage[data-checkout-stage="shipping"] [data-address-mode="customer"] .shipping-address-block {
  display: none; }

.data-checkout-stage[data-checkout-stage="shipping"] [data-address-mode="shipment"] .shipping-address-form {
  display: none; }

.data-checkout-stage[data-checkout-stage="shipping"] [data-address-mode="edit"] .shipping-address-block {
  display: none; }

.data-checkout-stage[data-checkout-stage="shipping"] [data-address-mode="new"] .btn-show-details,
.data-checkout-stage[data-checkout-stage="shipping"] [data-address-mode="new"] .btn-add-new,
.data-checkout-stage[data-checkout-stage="shipping"] [data-address-mode="new"] .btn-show-details + span {
  display: none; }

.data-checkout-stage[data-checkout-stage="payment"] .shipping-section,
.data-checkout-stage[data-checkout-stage="payment"] .card.ghost {
  display: none; }

.data-checkout-stage[data-checkout-stage="payment"] .card.payment-form,
.data-checkout-stage[data-checkout-stage="payment"] .shipping-summary {
  display: block; }

.data-checkout-stage[data-checkout-stage="payment"] button.submit-payment,
.data-checkout-stage[data-checkout-stage="payment"] .back-from-payment {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.data-checkout-stage[data-checkout-stage="payment"] .address-selector-block .btn-show-details,
.data-checkout-stage[data-checkout-stage="payment"] .address-selector-block .btn-add-new {
  border: none; }

.data-checkout-stage[data-checkout-stage="payment"] .terms-and-conditions-link {
  display: none; }

.data-checkout-stage[data-checkout-stage="payment"] [data-address-mode="customer"] .billing-address {
  display: none; }

.data-checkout-stage[data-checkout-stage="payment"] [data-address-mode="shipment"] .billing-address {
  display: none; }

.data-checkout-stage[data-checkout-stage="payment"] [data-address-mode="edit"] .billing-address {
  display: none; }

.data-checkout-stage[data-checkout-stage="payment"] [data-address-mode="new"] .btn-show-details,
.data-checkout-stage[data-checkout-stage="payment"] [data-address-mode="new"] .btn-add-new,
.data-checkout-stage[data-checkout-stage="payment"] [data-address-mode="new"] .btn-show-details + span {
  display: none; }

.data-checkout-stage[data-checkout-stage="payment"] [data-address-mode="details"] .btn-show-details,
.data-checkout-stage[data-checkout-stage="payment"] [data-address-mode="details"] .btn-add-new,
.data-checkout-stage[data-checkout-stage="payment"] [data-address-mode="details"] .btn-show-details + span {
  display: none; }

.data-checkout-stage[data-checkout-stage="placeOrder"] .shipping-section,
.data-checkout-stage[data-checkout-stage="placeOrder"] .card.payment-form,
.data-checkout-stage[data-checkout-stage="placeOrder"] .card.ghost {
  display: none; }

.data-checkout-stage[data-checkout-stage="placeOrder"] .card.payment-summary,
.data-checkout-stage[data-checkout-stage="placeOrder"] .shipping-summary {
  display: block; }

.data-checkout-stage[data-checkout-stage="placeOrder"] button.place-order,
.data-checkout-stage[data-checkout-stage="placeOrder"] .back-from-place-order {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

@media (max-width: 768.98px) {
  .data-checkout-stage[data-checkout-stage="placeOrder"] button.extra-place-order {
    display: none; } }

.data-checkout-stage[data-checkout-stage="placeOrder"] .terms-and-conditions-link {
  display: block; }

.data-checkout-stage[data-checkout-stage="placeOrder"] .terms-and-conditions-link a {
  text-decoration: underline; }

.data-checkout-stage[data-checkout-stage="submitted"] .shipping-form,
.data-checkout-stage[data-checkout-stage="submitted"] .card.payment-form,
.data-checkout-stage[data-checkout-stage="submitted"] .card.ghost {
  display: none; }

.data-checkout-stage[data-checkout-stage="submitted"] .summary-section-label.shipping-addr-label {
  display: none; }

.data-checkout-stage[data-checkout-stage="submitted"] .card.payment-summary,
.data-checkout-stage[data-checkout-stage="submitted"] .shipping-summary {
  display: block; }

.data-checkout-stage[data-checkout-stage="submitted"] .next-step-button {
  display: none; }

.data-checkout-stage option[value="new"],
.data-checkout-stage .custom-select-option[data-value="new"] {
  display: none; }

.data-checkout-stage h5 > span {
  font-size: 0.8em; }

[data-customer-type="guest"] .single-shipping .shipment-selector-block {
  display: none; }

.single-shipping .multi-ship-action-buttons {
  display: none; }

.single-shipping .view-address-block {
  display: none; }

#paymentMethodsList {
  margin: 0;
  padding: 0; }

.adyen-checkout__dropdown__button {
  height: 55px !important; }
